
import Vue from "vue";
import LogIn from "@/components/LandingPage/LogIn.vue";
import Steps from "@/components/LandingPage/Steps.vue";
import LandingBackground from "@/components/LandingPage/LandingBackground.vue";
import LandingDiscoverMentorcasts from "@/components/LandingPage/LandingDiscoverMentorcasts.vue";
import LandingDiscoverMentors from "@/components/LandingPage/LandingDiscoverMentors.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "LandingPage",
  components: {
    LandingBackground,
    LogIn,
    Steps,
    LandingDiscoverMentorcasts,
    LandingDiscoverMentors
  },

  computed: {
    ...mapGetters({
      landing_page: "helper/getLandingPage"
    })
  },

  mounted() {
    this.$store.dispatch("helper/GET_LANDING_PAGE");
  }
});
