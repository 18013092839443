
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "LandingBackground",

  data() {
    return {
      loading: true
    };
  },

  computed: {
    isAVideo(): boolean {
      return this.item.background_video.includes(".mp4");
    },
    ...mapGetters({
      item: "helper/getLandingPage"
    })
  },

  mounted() {
    this.$store.dispatch("helper/GET_LANDING_PAGE").then(() => {
      this.loading = false;
    });
  }
});
